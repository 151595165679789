import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Antd from 'ant-design-vue';
import 'ant-design-vue/dist/antd.css';
import './assets/font_4473603_lznblizk9s9/iconfont.css'
import axios from 'axios'
import NProgress from 'nprogress'
import 'nprogress/nprogress.css'
import * as echarts from 'echarts'

// let currentVersion = localStorage.getItem('appVersion') || '1.0.0';

// // 应用启动时立即检查一次版本
// fetch('/version.json?t=' + Date.now())
//   .then(res => res.json())
//   .then(data => {
//     const latestVersion = data.version;
//     if (latestVersion !== currentVersion) {
//       localStorage.setItem('appVersion', latestVersion); // 存储最新版本
//       window.location.reload(true); // 立即刷新
//     }
//   });

// // 轮询检查版本更新
// setInterval(() => {
//   fetch('/version.json?t=' + Date.now())
//     .then(res => res.json())
//     .then(data => {
//       const latestVersion = data.version;
//       const savedVersion = localStorage.getItem('appVersion');

//       if (latestVersion !== savedVersion) {
//         if (confirm('发现新版本，点击确定立即更新！')) {
//           localStorage.setItem('appVersion', latestVersion);
//           window.location.reload(true);
//         }
//       }
//     });
// }, 1000); // 每60秒检查一次
const app = createApp(App)
app.echarts = echarts
NProgress.configure({
    easing:'ease',
    speed:500,
    showSpinner:false,
    trickleSpeed:200,
    minimum:0.3
  })
app.use(store).use(Antd).provide('axios',axios).use(router).mount('#app')
