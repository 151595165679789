// import axios from "axios";
import service from '../request/request'


// 登录
export function Login(data){
  return service({url:'/api/public/login',method: 'POST', data})
}
// 退出登录
export function OutLogin(data){
  return service({url:'/api/public/loginOut',method: 'POST', data})
}


// 首页 用户汇总数据
export function Daily(data) {
  return service({url:"/api/report/summary",method: 'POST', data});
}


// 小时数据
export function useLog(data) {
  return service({url:"/api/report/hourly",method: 'POST', data});
}
// 数据报表  查询
export function Inquire(data) {
  return service({url:"/api/report/daily",method: 'POST', data});
}
// 广告位下拉
export function Domain() {
  return service({url:"/api/report/slot_select",method: 'POST'});
}
// 国家下拉
export function State() {
  return service({url:"/api/report/country_select",method: 'POST'});
}
// 媒体下拉
export function Media() {
  return service({url:"/api/report/domain_select",method: 'POST'});
}
// 柱状图
export function period(data) {
  return service({url:"/api/graphReport/period",method: 'POST', data});
}




// 
export function realDaily(data) {
  return service({url:"/api/real_time_report/daily",method: 'POST', data});
}
// 
export function realHourly(data) {
  return service({url:"/api/real_time_report/hourly",method: 'POST', data});
}

// 下载
export function daily_excel(data) {
  return service({url:"/api/report/daily_excel",method: 'POST', responseType: 'blob', data});
}



// 实时数据通道
export function sschannel_select(data) {
  return service({url:"/api/real_time_report/channel_select",method: 'POST', data});
}
// 数据报表通道
export function bbchannel_select(data) {
  return service({url:"/api/report/channel_select",method: 'POST', data});
}